<template>
    <div>
        <div align="center">
            <span class="text-h5">Documenti associati ai Contratti</span>
            <hr>
        </div>
        <div class="justify-center row">

            <div class="col-12 col-md-8 q-mr-sm">
                <QQElencoDocumentiPratica
                    title=""
                    tabIndex = "0"
                    showContratti
                    :id_pratica="IDPratica"
                    :formPratica="formPratica"
                />
            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="TORNA AL MENU"
                        color="blue-grey"
                        icon="undo"
                        size="md"
                        icon_align="left"
                        @click.native="onIndietroClicked()"
                    />
                </div>

            </div>
        </div>
        <br><br><br><br><br><br><br>
    </div>
</template>
<script>
    //import { mapFields} from 'vuex-map-fields';
    import { mapState } from 'vuex';
    import QQElencoDocumentiPratica from "@/components/QQElencoDocumentiPratica.vue";
    //import commonLib from "@/libs/commonLib";
    import QQButton from "@/components/QQButton.vue";

    export default {
        name: "AnnullaPratica",
        components: {
            QQButton,
            QQElencoDocumentiPratica
        },
        data() {
            return {

            }
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPratica: state => state.gestionePratiche.formPratica
            }),
            IDPratica() {
                return this.formPratica.preventivo.id;
            }
        },
        methods: {
            onIndietroClicked() {
                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
            }
        }
    }
</script>
